/* eslint-disable */
import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { check } from 'react-icons-kit/feather/check';
import { playCircle } from 'react-icons-kit/fa/playCircle';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Rating from 'common/components/Rating';
import Container from 'common/components/UI/Container';
import Parent from './Parent'
//import Vertical from './TextTransform'
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  Features,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';

import microsoft from 'common/assets/image/appModern/envato-icon.png';
import bannerImg from 'common/assets/image/appModern/banner2.png';
import videoBanner1 from 'common/assets/image/appModern/video-1.png';
import videoBanner2 from 'common/assets/image/appModern/video-2.png';
import circleBorder from 'common/assets/image/appModern/shape.svg';
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent2 = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://paycare.com/crypto/#/exchange/btc-to-eth"
      frameBorder="0"
    />
  </VideoWrapper>
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://paycare.com/crypto/#/createWallet"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { client } = data.appModernJson;
    // modal handler
    const handleVideoModal2 = () => {
      openModal({
        config: {
          className: 'video-modal',
          disableDragging: true,
          default: {
            width: '90%',
            height: 'auto',
            x: 0,
            y: 0,
          },
        },
        component: ModalContent2,
        componentProps: {},
        closeComponent: CloseModalButton,
        closeOnClickOutside: true,
      });
    };
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: '90%',
          height: '100%',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up>
            <RatingInfo>
              protected by <img src={microsoft} alt="protected by PayCare" />
            </RatingInfo>
          </Fade>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Secure, fast and easy
global payment services"
            />
          </Fade>
       
          <Fade up delay={200}>
            <Text
              content="Accept cryptocurrency payments globally on PayCare Escrow Payment Network"
            />
            <Text
              content="a smarter way to trade and manage your crypto with PayCare" />
          </Fade>
          <Features>
              <li>
                <Icon size={20} icon={check} /> Trade with escrow risk-free Protection
              </li>
              <li>
                <Icon size={20} icon={check} /> Smart contract protocol
              </li>
              <li>
                <Icon size={20} icon={check} /> Global payment system
              </li>
            </Features>

          <Fade up delay={100}>
            <ButtonGroup>
              <Button className="primary" title="Create Wallet" />
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={playCircle} />}
                iconPosition="left"
                title="Trade"
              />
            </ButtonGroup>
          </Fade>
          <VideoGroup>
            <img
              src={videoBanner1}
              onClick={handleVideoModal}
              alt="Microsoft"
            />
            <img
              src={videoBanner2}
              onClick={handleVideoModal2}
              alt="Microsoft"
            />
          </VideoGroup>
        </BannerContent>
        <BannerImage>
          <Fade up delay={200}>
     
            <Parent />

          </Fade>
        </BannerImage>
      </Container>

      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="PayCare"
      />
    </BannerWrapper>
  );
};

export default Banner;

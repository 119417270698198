import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appminimal';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import Banner from 'containers/AppModern/Banner';

import KeyFeatures from 'containers/AppClassic/KeyFeatures';
import WalletExperience from 'containers/AppMinimal/WalletExperience';

import MilestoneBlock from 'containers/Charity/MilestoneBlock';
import WalletDashboard from 'containers/AppMinimal/WalletDashboard';
import FeatureTab from 'containers/AppClassic/FeatureTab';
//import UpdateScreen from 'containers/SaasClassic/UpdateScreen';
import Gallery from 'containers/AppMinimal/Gallery';


import Pricing from 'containers/AppMinimal/Pricing';
import Blog from 'containers/AppMinimal/Blog';
// kesobb import ClientsSection from 'containers/Portfolio/Clients';
//import PricingPolicy from 'containers/AppClassic/PricingPolicy';
//import Testimonial from 'containers/AppClassic/Testimonial';
//import Faq from 'containers/AppClassic/Faq';
//import JoinTrail from 'containers/AppClassic/JoinTrail';
//import Footer from 'containers/AppClassic/Footer';
import BetaSections from 'containers/Crypto/BetaSection';


import Subscription from 'containers/SaasMinimal2/Subscription';
import Footer from 'containers/AppMinimal/Footer';
import SEO from 'components/seo';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
  GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import '@redq/reuse-modal/es/index.css';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="SaaS Minimal | A react Gatsby landing page" />
        {/* end of head */}

        <ResetCSS />
        <GlobalStyle />
        {/* end of global and reset style */}

        {/* start app minimal landing */}
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Banner />
            <KeyFeatures />
			<WalletExperience />
            <MilestoneBlock />
			<WalletDashboard />
            <FeatureTab />



          </ContentWrapper>
          <GradientWrapper>
            <Gallery />

            <Pricing />
            <Blog />
            <BetaSections />
			<Subscription />
            <Footer />
          </GradientWrapper>
        </AppWrapper>
        {/* end of app minimal landing */}
      </>
    </ThemeProvider>
  );
}

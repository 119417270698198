import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import BannerBG from 'common/assets/image/appmodern/banner-bg.jpg';


const BannerWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 160px;
  min-height: 802px;
  background-image: url(${BannerBG});

  background-size: cover;
  zindex:-9999;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;

  background-image: -ms-linear-gradient(
    139deg,
    rgb(100, 43, 115) 0%,
    rgb(198, 66, 110) 100%
  );
  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    min-height: 100vh;
	  padding-top: 100px;
  padding-bottom: 0px;
  min-height: 802px;
  background-image: url(${BannerBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  }
  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
   div.container {
    display: flex;
    align-items: center;
	-webkit-justify-content: space-between;
    min-height: calc(802px - 100px);
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
      min-height: calc(100vh - 100px);
      margin-top: 35px;
      align-items: flex-start;
    }
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }
  .bannerBottomShape {
    position: absolute;
    right: 0;
    bottom: -2px;
    z-index: -999;
    @media only screen and (max-width: 999px) {
      display: none;
    }
  }
`;

export const Features = styled.ul`
  display: flex;
  justify-content: left;
  margin: 30px 0 10px 0;
  @media only screen and (max-width: 480px) {
    display: block;
    margin-top: 30px;
  }
  li {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.29;
    color: #007DFF;
    margin-right: 18px;
    display: flex;
    align-items: center;
    background: #d2dde7;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #e9ecef;
    &:last-child {
   
    }
    i {
      display: inline-flex !important;
      margin-right: 8px;
      color: #525f7f;
    }
    @media only screen and (max-width: 480px) {
      line-height: 1.8;
    }
  }
`;

export const BannerContent = styled.div`
  width: 56%;
  flex-shrink: 0;
  @media only screen and (max-width: 1199px) {
    flex-shrink: 0;
    width: 100%;
    margin-top: 25px;
  }

  h1 {
    font-size: 62px;
    line-height: 1.2;
    font-weight: 800;
    color: #849aa8;
    margin-bottom: 24px;
    @media only screen and (max-width: 1366px) {
      font-size: 50px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
  }

  p {
    color: #1395df;
    font-size: 18px;
    line-height: 1.8;
  }
`;

export const RatingInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  color: #1395df;

  .rating {
    margin-right: 10px;
    .star {
      color: #ddd;
    }
    .star-o {
      color: #e8e8e8;
    }
  }

  img {
    margin-left: 9px;
  }
`;

export const BannerImage = styled.div`
  flex-shrink: 0;
  img {
    margin-left: 0px;
    margin-top: 50px;
    @media only screen and (max-width: 1440px) {
      margin-top: 0px;
    }
  }
  @media only screen and (max-width: 1199px) {
    display: none;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 35px;

  .reusecore__button {
    text-transform: inherit;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;

    &.primary {
      background-color: #0b6df1;
      color: #fff;
      font-size: 16px;
      letter-spacing: -0.1px;
      &:hover {
        box-shadow: #1e2a4a 0px 12px 24px -10px;
      }
    }

    &.text {
      font-size: 16px;
      color: #ddd;
      letter-spacing: -0.1px;
      font-weight: 500;
      .btn-icon {
        i {
          color: #d2d6d9;
        }
        svg {
          width: auto;
          height: 25px;
        }
      }
    }
  }
`;
export const VideoGroup = styled.div`
  display: flex;
  margin-top: 60px;
  @media only screen and (max-width: 768px) {
    margin-top: 45px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  img {
    margin-right: 10px;
    max-width: 100%;
    object-fit: cover;
    cursor: pointer;
    height: 100%;
    @media only screen and (max-width: 600px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
`;
export const VideoWrapper = styled.div`
  max-width: 100%;
  width: 900px;
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export const CustomerWrapper = styled.div`
  max-width: 850px;
  padding: 0px 0 70px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    padding: 0px 0 55px;
  }
  @media only screen and (max-width: 1099px) {
    padding: 55px 0 55px;
    max-width: 920px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 25px 0 40px;
  }

  p {
    margin: 0 12px 0 0;
    color: ${themeGet('colors.menu', '#0D233E')};
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
  img {
    margin: 0 12px;
    @media only screen and (max-width: 667px) {
      margin: 5px 10px;
    }
  }
`;
export default BannerWrapper;
